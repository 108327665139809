import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  // Set an item in the storage
  setItem(key: string, value: any, useSessionStorage: boolean = false): void {
    try {
      const storage = useSessionStorage ? sessionStorage : localStorage;
      storage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error("Error saving to storage", e);
    }
  }

  // Get an item from the storage
  getItem(key: string, useSessionStorage: boolean = false): any {
    try {
      const storage = useSessionStorage ? sessionStorage : localStorage;
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (e) {
      console.error("Error getting data from storage", e);
      return null;
    }
  }

  // Remove an item from the storage
  removeItem(key: string, useSessionStorage: boolean = false): void {
    try {
      const storage = useSessionStorage ? sessionStorage : localStorage;
      storage.removeItem(key);
    } catch (e) {
      console.error("Error removing item from storage", e);
    }
  }

  // Clear the storage
  clear(useSessionStorage: boolean = false): void {
    try {
      const storage = useSessionStorage ? sessionStorage : localStorage;
      storage.clear();
    } catch (e) {
      console.error("Error clearing storage", e);
    }
  }
}
