import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BreadcrumbModule } from "angular-crumbs";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./preloader/preloader.component";

import { FirebaseService } from "./components/helper/service/firebase.service";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";

@NgModule({
  declarations: [AppComponent, PreloaderComponent],
  imports: [
    NgbModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BreadcrumbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxGoogleAnalyticsRouterModule,
    NgxGoogleAnalyticsModule.forRoot("G-GPEBE26E2Y"), // Add to Env
  ],
  providers: [
    {
      provide: FirebaseService,
      useClass: FirebaseService,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
