import { Component, HostListener, OnInit } from "@angular/core";

import { StorageService } from "../components/helper/service/storage.service";
import { FirebaseService } from "../components/helper/service/firebase.service";
import { IYoutubeApi } from "../components/interfaces/youtube/youtube.interface";

@Component({
  selector: "app-preloader",
  templateUrl: "./preloader.component.html",
  styleUrls: ["./preloader.component.css"],
})
export class PreloaderComponent implements OnInit {
  windowLoad: boolean | undefined;
  constructor(
    private storage: StorageService,
    private firebase: FirebaseService
  ) {}
  // Preloader
  @HostListener("window:load", [])
  onWindowLoad() {
    this.windowLoad = true;
  }

  ngOnInit(): void {
    // const listVideo = this.storage.getItem("listVideo");
    // if (listVideo == null)
    //   this.firebase.listVideos().subscribe((video) => {
    //     this.storage.setItem("listVideo", video);
    //   });
  }
}
