import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { BreadcrumbService, Breadcrumb } from "angular-crumbs";

import { track } from "@vercel/analytics";
import { injectSpeedInsights } from "@vercel/speed-insights";

import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { FirebaseService } from "./components/helper/service/firebase.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private metaService: Meta,
    private titleService: Title,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe((crumbs) => {
      this.titleService.setTitle(this.createTitle(crumbs));
      this.metaService.addTag({
        name: "description",
        content:
          "Rejoignez l'église protestantisme évangélique - l'Assemblée Évangélique des frères de Logne : cultes, études bibliques, réunions de prière",
      });
    });
    track("AppComponent load");
  }
  onActivate(_event: any) {
    window.scroll(0, 0);
  }
  private createTitle(routesCollection: Breadcrumb[]) {
    const title = "Assemblée Évangélique des fréres - Église sur Logne";
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) {
      return title;
    }

    const routeTitle = this.titlesToString(titles);
    return `${title}${routeTitle}`;
  }

  private titlesToString(titles: any[]) {
    return titles.reduce((prev, curr) => {
      return `${prev} | ${curr.displayName}`;
    }, "");
  }
}
