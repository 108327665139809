// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

import { defaultEnvironment } from "./default-environment";

// The list of file replacements can be found in `angular.json`.
export const environment = {
  ...defaultEnvironment,
  isOnLive: "https://isonline2-tfalhlpdcq-uc.a.run.app",
  listVideos: "https://listvideos-tfalhlpdcq-uc.a.run.app",
  channel: "UCO6K_kkdP-lnSCiO3tPx7WA",
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
