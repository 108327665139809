export const defaultEnvironment = {
    firebase: {
        measurementId: "G-GPEBE26E2Y",
        projectId: "circular-gist-403813",
        messagingSenderId: "758883349539",
        apiKey: "AIzaSyBXR31sOSZdJdoXtsR7ZLehcXKcvl8Ul6I",
        storageBucket: "circular-gist-403813.appspot.com",
        authDomain: "circular-gist-403813.firebaseapp.com",
        appId: "1:758883349539:web:690ef0318fcc4e7e3bed96",
        databaseURL: "https://circular-gist-403813-default-rtdb.europe-west1.firebasedatabase.app",
    }
};