import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";

import { initializeApp, FirebaseApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import {
  initializeAnalytics,
  logEvent,
  Analytics,
  AnalyticsSettings,
} from "firebase/analytics";
import {
  ref,
  get,
  child,
  query,
  startAt,
  equalTo,
  getDatabase,
  orderByChild,
} from "firebase/database";

import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import {
  IApiOnline,
  IYoutubeApi,
} from "../../interfaces/youtube/youtube.interface";
import { GoogleAnalyticsService } from "ngx-google-analytics";

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  private app: FirebaseApp = initializeApp(environment.firebase);
  private fn = getFunctions(this.app, "us-central1");

  constructor(
    private http: HttpClient,
    protected $gaService: GoogleAnalyticsService
  ) {}

  trackUser(
    page_title: string = "",
    page_location: string = "",
    event_name: string = "page_view"
  ) {
    const data: AnalyticsSettings = {
      config: {
        page_title: page_title,
        page_location: page_location,
      },
    };
    const analytic: Analytics = initializeAnalytics(this.app, data);
    logEvent(
      analytic,
      event_name,
      { currency: "EUR", page_title: page_title },
      { global: true }
    );
    this.$gaService.pageView(page_location, page_title);
  }

  isOnLive() {
    return this.http.get<IApiOnline>(environment.isOnLive);
  }

  listVideos(): Observable<IYoutubeApi[]> {
    return this.http.get<IYoutubeApi[]>(environment.listVideos);
  }

  getSermonsById(id: string) {
    if (id === null || id === undefined || id.trim() === "") id = "0";
    const sermonsId = Number.isNaN(Number(id)) ? Number(id) - 1 : 0;
    return get(child(ref(getDatabase(this.app)), `sermons/${sermonsId}`));
  }

  getAllSermons() {
    return query(
      ref(getDatabase(this.app), "sermons"),
      orderByChild("position")
    );
  }

  getEventById(id: string) {
    return query(
      ref(getDatabase(this.app), "events"),
      orderByChild("eventdate"),
      equalTo(id, "id")
    );
  }

  getAllEvent() {
    return query(
      ref(getDatabase(this.app), "events"),
      orderByChild("eventdate"),
      startAt(new Date().toISOString().split("T")[0], "eventdate")
    );
  }
}
